var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_c('label',{staticClass:"btn btn-outline-primary font-scale-2 font-medium",attrs:{"title":"Автомобили"},on:{"click":function($event){return _vm.$router.push('/cars')}}},[_vm._v(" Автомобили ")]),_c('label',{staticClass:"btn btn-outline-primary font-scale-2 font-medium",attrs:{"title":"Продавцы"},on:{"click":function($event){return _vm.$router.push('/cars/selling')}}},[_vm._v(" Продавцы ")]),_c('label',{staticClass:"btn btn-primary font-scale-2 font-medium"},[_vm._v(" "+_vm._s(_vm.page_title)+" ")])],1),_c('div',{staticClass:"form-group mt-3"},[_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"show_only-my","autocomplete":"off"},domProps:{"checked":_vm.filter_tasks.show_only === 'my'},on:{"input":() => {
               _vm.filter_tasks.show_only = 'my';
               _vm.getTasksEvents();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"show_only-my"}},[_vm._v("Мои")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"show_only-all","autocomplete":"off"},domProps:{"checked":_vm.filter_tasks.show_only === 'all'},on:{"input":() => {
               _vm.filter_tasks.show_only = 'all';
               _vm.getTasksEvents();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"show_only-all"}},[_vm._v("Все")])])]),_c('div',{staticClass:"box-body"},[_c('FullCalendar',{staticStyle:{"background":"#fff","padding":"12px"},attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('div',{staticClass:"label w-100 font-small",style:('display: inline-grid; white-space: break-spaces; background: ' + arg.event.extendedProps.backgroundColorType)},[_c('p',{staticClass:"font-medium my-0"},[_c('strong',{staticClass:"font-scale"},[_vm._v(_vm._s(arg.event.extendedProps.time)+" ")]),_vm._v(" "+_vm._s(arg.event.title)+" ")]),(arg.event.extendedProps.type_state_name)?_c('label',{staticClass:"label font-medium my-0",style:('background: ' + arg.event.extendedProps.backgroundColorTypeState)},[_vm._v(" "+_vm._s(arg.event.extendedProps.type_state_name)+" ")]):_vm._e(),(arg.event.extendedProps.car_info)?_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"fas fa-car-alt"}),_vm._v(" "+_vm._s(arg.event.extendedProps.car_info)+" ")]):_vm._e(),(arg.event.extendedProps.client_info)?_c('p',{staticClass:"my-0"},[_c('i',{staticClass:"fas fa-user-circle"}),_vm._v(" "+_vm._s(arg.event.extendedProps.client_info)+" ")]):_vm._e(),(arg.event.extendedProps.responsible_info)?_c('p',{staticClass:"font-medium my-0"},[_c('i',{staticClass:"fas fa-user-tie"}),_vm._v(" "+_vm._s(arg.event.extendedProps.responsible_info)+" ")]):_vm._e()])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }