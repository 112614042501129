<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars')"
               title="Автомобили">
          Автомобили
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars/selling')"
               title="Продавцы">
          Продавцы
        </label>
        <label class="btn btn-primary font-scale-2 font-medium">
          {{ page_title }}
        </label>
      </h3>

      <div class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_tasks.show_only = 'my';
                 getTasksEvents();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_tasks.show_only = 'all';
                 getTasksEvents();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>
    </div>

    <div class="box-body">
      <FullCalendar :options="calendarOptions" style="background: #fff; padding: 12px;">
        <template v-slot:eventContent="arg">
          <div class="label w-100 font-small"
             :style="'display: inline-grid; white-space: break-spaces; background: ' + arg.event.extendedProps.backgroundColorType">
            <p class="font-medium my-0">
              <strong class="font-scale">{{ arg.event.extendedProps.time }} </strong>
              {{ arg.event.title }}
            </p>
            <label v-if="arg.event.extendedProps.type_state_name"
                   class="label font-medium my-0"
                   :style="'background: ' + arg.event.extendedProps.backgroundColorTypeState">
              {{ arg.event.extendedProps.type_state_name }}
            </label>
            <p v-if="arg.event.extendedProps.car_info" class="my-0">
              <i class="fas fa-car-alt"></i>
              {{ arg.event.extendedProps.car_info }}
            </p>
            <p v-if="arg.event.extendedProps.client_info" class="my-0">
              <i class="fas fa-user-circle"></i>
              {{ arg.event.extendedProps.client_info }}
            </p>
            <p v-if="arg.event.extendedProps.responsible_info" class="font-medium my-0">
              <i class="fas fa-user-tie"></i>
              {{ arg.event.extendedProps.responsible_info }}
            </p>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>

</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";

export default {
  name: "cars-calendar",

  components: {
    FullCalendar,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Календарь",

    calendarOptions: {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrap5Plugin,
      ],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      buttonIcons: {
        prev: ' fas fa-chevron-left',
        next: ' fas fa-chevron-right',
        prevYear: ' fas fa-chevron-left',
        nextYear: ' fas fa-chevron-right',
      },
      initialView: "dayGridMonth",
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      locale: ruLocale,
      themeSystem: "bootstrap5",
      // events: [
      //   { title: 'event 1', date: '2023-04-12 12:00', time: '12:00', backgroundColor: '#007bff' },
      //   { title: 'event 2', date: '2023-04-14 14:30', time: '14:30', backgroundColor: '#ffc107' },
      // ],
      events: null,
    },
    currentEvents: [],

    tasks: [],

    filter_tasks: {
      show_only: null,
    },

    onProgress: true,
  }),

  async created() {
    try {
      if ([1, 4, 5, 6, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
        this.filter_tasks.show_only = "all";
      } else {
        this.filter_tasks.show_only = "my";
      }

      await this.getTasksEvents();

    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends;
    },

    handleDateSelect(selectInfo) {

    },

    handleEventClick(clickInfo) {

    },

    handleEvents(events) {

    },

    async getTasksEvents() {
      const tasks_response = await API.apiClient.get("/tasks/scheduled", {
        params: {
          relations: [
            "type",
            "type_state",
            "car",
            "client",
            "responsible",
          ],
          filters_query: this.filter_tasks,
        },
      });

      this.tasks = tasks_response.data;
      this.calendarOptions.events = this.tasks;
    },
  },
};
</script>

<style scoped>

</style>
